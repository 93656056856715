import { FC, memo } from "react";

import { Size, sizeClassMap } from "./Spacer.map";

export interface ISpacer {
  size?: Size;
  customSize?: string;
  className?: string;
}

const Spacer: FC<ISpacer> = ({ size = "xl", className, customSize }) => (
  <div data-component="Spacer" className={`block w-full ${!customSize ? sizeClassMap[size] : customSize} ${className}`} />
);

export default memo(Spacer);
