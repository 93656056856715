import {
  BreakpointMap,
  BreakpointProp,
} from "apps/website/maps/Breakpoint.map";

export type BreakpointClassMap = [
  string | number | BreakpointProp,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any>, BreakpointMap,
];

export const makeBreakpointClassesFromMap = (
  prop: string | number | BreakpointProp,
  defaultMap: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  breakpointMap: BreakpointMap,
): string => {
  if (typeof prop === "string" || typeof prop === "number") return defaultMap[prop as keyof typeof defaultMap];
  const collectiveMap = [
    breakpointMap.default?.[prop.default as keyof typeof breakpointMap.default] || breakpointMap?.default?.["default" as keyof typeof breakpointMap.default],
    breakpointMap.sm?.[prop.sm as keyof typeof breakpointMap.sm],
    breakpointMap.md?.[prop.md as keyof typeof breakpointMap.md],
    breakpointMap.lg?.[prop.lg as keyof typeof breakpointMap.lg],
    breakpointMap.xl?.[prop.xl as keyof typeof breakpointMap.xl],
    breakpointMap?.["2xl"]?.[prop["2xl"] as keyof typeof breakpointMap["2xl"]],
    breakpointMap?.["3xl"]?.[prop["3xl"] as keyof typeof breakpointMap["3xl"]],
  ].filter((map) => map !== undefined);

  return collectiveMap.join(" ").trim();
};

export const makeBreakpointClasses = (
  classMaps: BreakpointClassMap[],
  inheritedClasses = "",
): string | string[] => [
  classMaps.map((classMap) => makeBreakpointClassesFromMap(classMap[0], classMap[1], classMap[2])).join(" "),
  inheritedClasses,
].join(" ").trim();
