import { FC, ReactNode, memo } from "react";
import parse from "html-react-parser";

import { makeBreakpointClasses } from "apps/website/utils/classes";
import { WithDataAttr } from "apps/website/types";

import * as TextMap from "./Text.map";

export interface IText extends WithDataAttr {
  id?: string;
  tag?: TextMap.AvailableTag;
  align?: TextMap.Align | TextMap.BreakpointAlign;
  display?: TextMap.Display;
  color?: TextMap.Color;
  size?: TextMap.Size | TextMap.BreakpointSize;
  className?: string;
  whitespace?: TextMap.Whitespace | TextMap.BreakpointWhiteSpace;
  onClick?: () => void; // This should not be abused and only used when a standard button or link is present in the parent component
  parseChildren?: boolean;
  children: ReactNode;
}

const Text: FC<IText> = ({
  id,
  tag = "p",
  "data-testid": testId,
  "data-label": dataLabel,
  "data-title": dataTitle,
  "data-body": dataBody,
  align = "default",
  color = "default",
  display = "default",
  size = "default",
  className = undefined,
  whitespace = "default",
  onClick,
  parseChildren = false,
  children,
}) => {
  const Tag = tag;

  const computedStyleClasses = [
    TextMap.colorClassMap[color],
    TextMap.displayClassMap[display],
    className,
  ].join(" ");

  const computedClasses = makeBreakpointClasses([
    [ size, TextMap.defaultSizeClassMap, TextMap.collectiveSizeClassMap ],
    [ align, TextMap.alignClassMap, TextMap.collectiveAlignClassMap ],
    [ whitespace, TextMap.defaultWhitespaceClassMap, TextMap.collectiveWhitespaceClassMap ],
  ], computedStyleClasses);

  return (
    <Tag
      id={id}
      data-testid={testId}
      data-label={dataLabel}
      data-title={dataTitle}
      data-body={dataBody}
      data-component="Text"
      className={`${computedClasses}`}
      onClick={onClick}
    >
      { parseChildren ? parse(children as string) : children }
    </Tag>
  );
};
/**
 * @deprecated Should not be used on components outside of `TextBody`, `TextControl`, `TextSubtitle` or `TextTitle`
 *  going forward. Please choose the appropriate Higher order Text component in line with the designs provided.
 * */
export default memo(Text);
