export enum EStatusCodes {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  NOT_ACCEPTABLE = 406,
}

export type TResponse = EStatusCodes;

export function isResponseNotFound(status: TResponse): status is TResponse {
  return status === EStatusCodes.NOT_FOUND;
}

export function isResponseSuccess(status: TResponse): status is TResponse {
  return status === EStatusCodes.SUCCESS;
}

export function isResponseNotAcceptable(status: TResponse): status is TResponse {
  return status === EStatusCodes.NOT_ACCEPTABLE;
}

export function isResponseBadRequest(status: TResponse): status is TResponse {
  return status === EStatusCodes.BAD_REQUEST;
}
