// TODO: Share one spinner between applications
import { FC } from "react";

interface Props {
  size?: number;
  borderWidth?: number;
  className?: string;
  color?: string;
}

export const LoadingSpinner: FC<Props> = ({
  size = 64,
  borderWidth = size / 8,
  className = "",
  color = "rgb(67 56 202)",
}) => {

  const css = `
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-style: solid;
  border-color: rgba(0,0,0, 0.05);
  border-left: 1.1em solid ${color};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;

  return (
    <div
      data-component={LoadingSpinner.name}
      style={{
        height: size,
        width: size,
        borderWidth,
      }}
      className={`loader ${className}`}
    >
      <style>
        { css }
      </style>
    </div>
  );
};
