import { FC, memo, ReactNode } from "react";

import { DisplayState } from "@/constants/state";
import {
  LoadingSpinner,
} from "apps/website/components/form/LoadingSpinner/LoadingSpinner";

export type StateContent = {
  [key in DisplayState]?: ReactNode
};

interface IStateProps {
  state: DisplayState;
  content?: StateContent;
  children: ReactNode;
}

export const defaultStateContent: StateContent = {
  [DisplayState.PROCESSING]: <LoadingSpinner size={24} color="white" />,
  [DisplayState.LOADING]: <LoadingSpinner size={24} color="white" />,
  [DisplayState.COMPLETE]: "Complete",
  [DisplayState.EDITING]: "Editing",
  [DisplayState.ERROR]: "Something went wrong",
};

const ButtonState: FC<IStateProps> = ({
  state,
  content = defaultStateContent,
  children,
}) => (
  <>
    { state === DisplayState.READY && (children) }
    { state === DisplayState.PROCESSING && (content[DisplayState.PROCESSING]) }
    { state === DisplayState.LOADING && (content[DisplayState.LOADING]) }
    { state === DisplayState.COMPLETE && (content[DisplayState.COMPLETE]) }
    { state === DisplayState.EDITING && (content[DisplayState.EDITING]) }
    { state === DisplayState.ERROR && (content[DisplayState.ERROR]) }
  </>
);

export default memo(ButtonState);
