export const paddingClassMap = {
  default: "px-4",
  large: "px-8",
  none: "px-0",
};

export const sizeClassMap = {
  default: "container",
  sm: "container-sm",
  md: "container-md",
};

export type Padding = keyof typeof paddingClassMap;
export type Size = keyof typeof sizeClassMap;
